/* Main Container */
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px; /* Constrain the width for large screens */
    margin: 0 auto; /* Center the content */
    padding: 20px; /* Add some padding for better appearance */
    box-sizing: border-box;
    width: 100%;
  }
  
  /* Content Section */
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
  }
  
  /* Experiment Image Section */
  .experiment {
    width: 100%;
    max-width: 900px; /* Limit the maximum width of the image */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .experiment img {
    width: 100%; /* Image takes full width of its container */
    height: auto; /* Maintain the aspect ratio */
    object-fit: contain; /* Ensure the image fits inside without stretching */
  }
  
  /* Mobile-specific styles */
  @media (max-width: 768px) {
    .main {
      padding: 15px; /* Adjust padding for smaller screens */
    }
  
    .experiment img {
      width: 100%; /* Ensure the image remains full width on mobile */
    }
  }
  
  /* Desktop-specific styles */
  @media (min-width: 1024px) {
    .experiment {
      max-width: 650px; /* Restrict the image to max width for desktop */
    }
  
    .experiment img {
      width: 95%; /* Image fills up to 100% of the parent container's max width */
    }
  }
  

  .highlight {
    color: #e70404; /* Bright orange color */
    font-weight: bold;
   /* text-shadow: 2px 2px 5px #f7350a;  Adds a shadow for emphasis */
    transition: all 0.3s ease-in-out; /* Smooth hover effect */
  }
  
  .highlight:hover {
    color: #ffcc00; /* Bright yellow on hover */
    text-shadow: 2px 2px 10px rgba(255, 204, 0, 0.8);
  }
  

  .kpiContainer {
    width: 100%;
    text-align: center;
    background-color: #f8f9fa;
    padding: 1px 0;
    margin-top: 1px;
    border-top: 2px solid #ddd;
  }
  
  .kpiTitle {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1px;
  }
  
 
  .kpiItem {
    margin: 1px 0;
  }
  
  .kpiSubtitle {
    font-size: 2.0rem;
    color: #e70404; /* Bright yellow for contrast */
    margin-bottom: 1px;
  }
  
  .kpiValue {
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  
  
  .kpiLink {
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s ease-in-out;
  }
  
  .kpiLink:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
  }
  
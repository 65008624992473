.main {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  .main > h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .menu__link {
    font-size: 1rem;
    color: #ec0ba9;
    display: inline-block;
    text-decoration: underline;
  }
  
  .together {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .main img {
    width: 350px;
    height: 200px;
    border-radius: 12px;
  }
  
  .main > div > div {
    width: 40%;
  }
  
  .together {
    margin: 10px;
  }
  
  @media (max-width: 800px) {
    .together {
      flex-direction: column;
      align-items: center;
      width: 90%;
      gap: 10px;
      margin: auto;
    }
  
    .main > div {
      margin-bottom: 1px;
    }
  
    .main img {
      width: auto;
      margin: auto; /* Center the image */
    }
  
    #txt {
      width: 100%;
      text-align: center; /* Center-align the text on mobile */
    }
  
    #reverse_img {
      /* margin-top: 20px; */
      flex-direction: column-reverse;
    }
  
    .main > h1 {
      font-size: 30px;
    }
  }
  
  .main img {
    width: 350px;
    height: 200px;
    border-radius: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .main img:hover {
    transform: scale(1.20); /* Slight zoom effect */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
    cursor: pointer; /* Change cursor to pointer */
  }
  
  @media (max-width: 800px) {
    .together {
      flex-direction: column;
      align-items: center;
      width: 90%;
      gap: 10px;
      margin: auto;
    }
  
    .main > div {
      margin-bottom: 1px;
    }
  
    .main img {
      width: auto;
      margin: auto;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
  
    .main img:hover {
      transform: scale(1.20);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  
    #txt {
      width: 100%;
      text-align: center;
    }
  
    #reverse_img {
      /* margin-top: 20px; */
      flex-direction: column-reverse;
    }
  
    .main > h1 {
      font-size: 30px;
    }
  }
  
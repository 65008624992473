@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');


.demo-page{
  height: auto;
  width: 100%;
  padding: 20px 0;
  background: #ffffff;
}

.pic{
  padding: 20px;
  height: auto;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.pic2{
  width: 90%;
  margin: 0px auto 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
}

.about{
  width: 1130px;
  max-width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}

.about> img {
    border-radius: 10px;
}

.text{
  width: 80%;
}
.text h2{
  font-size: 35px;
  font-weight: 1000;
  margin-bottom: 10px;
}
.text h5{
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}
.text span{
  color: #4070f4;
}
.text p{
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 1px;
}
.data{
  margin-top: 30px;
}
.hire{
  font-size: 18px;
  background: #4070f4;
  color: #fff;
  text-decoration: none;
  border: none;
  padding: 8px 25px;
  border-radius: 6px;
  transition: 0.5s;
 
}
.hire:hover{
  background: #1a1818;
  border: 1px solid #4070f4;
}

.data >a >{
  display: flex;
  align-items: center;
}

.about2{
  width: 1130px;
  max-width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 100px;
}


.about2> img {
  border-radius: 10px;
}


.img_gallery{
  width: 80%;
  margin: 100px auto 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
}

.img_gallery img{
width: 100%;
cursor: pointer;
}

.img_gallery img:hover{
  transform: scale(1.2) rotate(-10deg);
  border-radius: 20px;
  box-shadow: 0 32px 75px rgba(68,77,136,0.2); 
  }

@media (max-width: 800px ){

   .about{
     display: flex;
     flex-direction: column;
   }

   .text{
    width: 100%;
    text-align: center;
   }

   .text>h2{
      font-size: 45px;
   }

   .pic{ 
    display: none;
}
  }


  .videowrapper {
    display: flex;
    flex-wrap: wrap; /* Allows the videos to wrap to the next line if there's no space */
    gap: 20px; /* Space between the videos */
    justify-content: center; /* Center the videos horizontally */
    margin: 20px 0;
  }
  .videocontainer {
    position: relative;
    padding-bottom: 177.77%; /* 9:16 Aspect ratio (vertical video) */
    height: 0;
    overflow: hidden;
    width: 48%; /* This makes each video take 48% of the container's width */
    background: #000;
  }
  
  .video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  /* Mobile view - Stack the videos vertically */
  @media (max-width: 768px) {
    .videowrapper {
      flex-direction: column; /* Stack the videos vertically */
      align-items: center; /* Center the videos */
    }
  
    .videocontainer {
      width: 90%; /* Videos take up 90% of the screen width on mobile */
      margin-bottom: 20px; /* Space between stacked videos */
    }
  }

  .faqSection {
    padding: 2px;
    background-color: #f9f9f9;
    margin: 0 auto;
    width: 90%;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faqTitle {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .faqItem {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .faqQuestion {
    font-size: 18px;
    font-weight: 500;
    color: #4070f4;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
  }
  
  .faqQuestion span {
    font-weight: bold;
    font-size: 20px;
  }
  
  .faqAnswer {
    font-size: 16px;
    color: #555;
    margin-top: 10px;
    padding-left: 10px;
  }
  

  .docFrame iframe,
  .formSection iframe {
    width: 60%;
    height: 600px;
    margin: 20px auto;
    display: block;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .formSection {
    text-align: center;
    margin: 20px auto 50px;
  }
  
  .formText {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .docFrame iframe,
    .formSection iframe {
      width: 90%;
      height: 300px;
    }
  
    .formText {
      font-size: 16px;
    }
  }
  

.about{
  width: 1130px;
  max-width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 100px;
}


.about> img {
  border-radius: 10px;
}

.text{
width: 80%;
}

.demo-page{
  height: auto;
  width: 100%;
  padding: 20px 0;
  background: #ffffff;
}

.body{
  background: #ecf4fb;
}

.img_gallery{
  width: 80%;
  margin: 100px auto 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
}

.img_gallery img{
width: 100%;
cursor: pointer;
}

.img_gallery img:hover{
  transform: scale(1.2) rotate(-10deg);
  border-radius: 20px;
  box-shadow: 0 32px 75px rgba(68,77,136,0.2); 
  }
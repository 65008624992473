@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");


.footer {
  position: relative;
  width: auto;
  background: #2cd192;
  min-height: 100px;
  padding: 10px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social-icon{
  position: relative;
  display: flex;
  justify-content: center; /* Centers the icons in a row */
  align-items: center;
  margin: 10px 0;
  flex-wrap: nowrap; /* Prevents wrapping to a new row */
}

.menu {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Aligns items to the left */
  align-items: flex-start;
  margin: 10px 0;
  width: 100%; /* Ensure the menu spans the full width */
}

.social-icon__item{
  list-style: none;
}

.menu__item {
  list-style: none;
  width: 50%; /* Makes two equal columns */
  margin-bottom: 10px; /* Adds space between rows */
}

.social-icon__link {
  font-size: 2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}
.social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1.1rem;
  color: #0c0000;
  text-decoration: none;
  transition: 0.5s;
  opacity: 0.75;
  font-weight: 500;
}

.menu__link:hover {
  opacity: 1;
}

.footer p {
  color: #070000;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 400;
}


.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #070000;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsappicon {
  margin-top: 16px;
}


@media screen and (max-width: 767px) {
  .whatsappicon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
      
  }
} 


.summer_camp_notification {
  position: fixed;
  width: 100px;
  height: 50px;
  bottom: 120px;
  right: 40px;
  background-color: #0e0101;
  border-radius: 50px;
  text-align: center;
  font-size: 50px;
  font-weight: 900;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

@keyframes glowing {
  0% {
    background-color: #0f0594;
    box-shadow: 0 0 3px #0f0594;
  }
  50% {
    background-color: #361c96;
    box-shadow: 0 0 10px #361c96;
  }
  100% {
    background-color: #f20d4a;
    box-shadow: 0 0 3px #f20d4a;
  }
}
.summer_camp_notification {
  animation: glowing 1600ms infinite;
}

@media screen and (max-width: 767px) {
  .summer_camp_notification {
      width: 100px;
      text-align: center;
      height: 40px;
      bottom: 70px;
      right: 10px;
      font-size: 22px;
      
  }
} 


.social-icon__item {
  list-style: none;
  margin-right: 30px; /* Adds 30px space between icons */
}

.social-icon__item:last-child {
  margin-right: 0; /* Removes extra margin from the last icon */
}
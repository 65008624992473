@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');


.demo-page{
  height: auto;
  width: 100%;
  padding: 20px 0;
  background: #ffffff;
}

.pic{
  padding: 20px;
  height: auto;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.about{
  width: 1130px;
  max-width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 100px;
}

.about> img {
    border-radius: 10px;
}

.text{
  width: 80%;
}
.text h2{
  font-size: 35px;
  font-weight: 1000;
  margin-bottom: 10px;
}
.text h5{
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}
.text span{
  color: #4070f4;
}
.text p{
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 1px;
}
.data{
  margin-top: 30px;
}
.hire{
  font-size: 18px;
  background: #4070f4;
  color: #fff;
  text-decoration: none;
  border: none;
  padding: 8px 25px;
  border-radius: 6px;
  transition: 0.5s;
 
}
.hire:hover{
  background: #1a1818;
  border: 1px solid #4070f4;
}

.data >a >{
  display: flex;
  align-items: center;
}

@media (max-width: 800px ){

   .about{
     display: flex;
     flex-direction: column;
   }

   .text{
    width: 100%;
    text-align: center;
   }

   .text>h2{
      font-size: 45px;
   }

   .pic{ 
    display: none;
}
  }
